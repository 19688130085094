<template>
  <FullScreenDialog
    v-model="invoiceDialog"
    :title="title"
    :routeFather="pathToGoBack"
    :routeFatherName="pathName"
    :otherGoBackParams="{ params: { filters: this.filters } }"
  >
    <template v-slot:header-actions>
      <div>
        <v-subheader>
          <v-col>
            <v-row
              :style="{
                'margin-top': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'auto' : '-12px'
              }"
            >
              <v-chip class="mr-7" :color="statusColor">
                {{ statusLabelColor }}
              </v-chip>
              <v-chip :color="typeColor">
                {{ typeLabelColor }}
              </v-chip>
            </v-row>
          </v-col>
        </v-subheader>
      </div>
    </template>
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab v-for="tab in invoicesCreditNoteTab" :key="tab.key">{{ tab.title }}</v-tab>
      </v-tabs>
    </template>

    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in invoicesCreditNoteTab" :key="tabItem.key">
          <v-card flat>
            <v-card-text class="pa-0">
              <component
                v-bind="currentProps"
                :is="tabItem.component"
              ></component>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-slot:footer-actions>
      <div>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="close"
          >Chiudi</v-btn
        >

        <v-btn
          v-if="currentComponent === 'CreditNoteFormTab' && variant !== 'detail'"
          text
          color="default"
          :disabled="!formValid || isLicenseBlocked"
          @click="submitForm"
          >Salva</v-btn
        >
        <v-btn text color="error" @click="payAndClose" v-if="variant === 'edit'"
          :disabled="isLicenseBlocked"
          >Paga totale & Chiudi</v-btn
        >
      </div>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import StandardMenu from "@/components/common/StandardMenu.vue";
import CreditNoteFormTab from "./form/CreditNoteFormTab.vue";
import invoiceService from "@/services/invoices/invoices.service.js";
import invoiceForm from "@/services/invoices/invoice.form.js";
import InvoiceElementFormEditTab from "@/components/areas/payments/invoices/form/InvoiceElementFormEditTab.vue";
import Vue from "vue";

export default {
  name: "InvoicesCreditNoteEditForm",
  components: {
    FullScreenDialog,
    StandardMenu,
    CreditNoteFormTab,
    InvoiceElementFormEditTab,
  },
  data: function () {
    return {
      id: undefined,
      tab: null,
      formValid: false,
      loadingInvoice: false,
      invoiceDialog: this.openDialog,
      title: "",
      typeColor: "",
      typeLabelColor: "",
      statusColor: "",
      statusLabelColor: "",
      invoicesCreditNoteTab: [
        {
          key: 0,
          title: "Nota a Credito",
          name: "general",
          component: "CreditNoteFormTab",
        },
      ],
      invoice: undefined,
      originalUrl: window.location.pathname,
      invoiceElements: [],
      currentComponent: "",
      bus: new Vue(),
      isLicenseBlocked: undefined
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName);

    this.id = this.$route.params.id;
    if (this.id) {
      this.fetchInvoice();
    }

    let self = this;
    invoiceForm.on("update", function (data) {
      self.invoice = data.invoice;
    });

    self.valid = invoiceForm.valid;
    invoiceForm.on("valid-change", function (valid) {
      self.formValid = valid;
    });

    this.bus.$on("reload-invoice", self.fetchInvoice);
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    pathToGoBack: {
      type: String,
      default: "/payments/invoicesToPay/list",
    },
    pathName: {
      type: String,
    },
    tabName: {
      type: String,
      default: 'general'
    },
    filters : {
      type: Array
    },
    variant: {
      type: String,
      default: "edit",
      validator: function (value) {
        if (!["edit", "detail"].includes(value)) {
          return "invalid variant";
        } else {
          return true;
        }
      },
    },
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for (let i = 0; i < this.invoicesCreditNoteTab.length; i++) {
        if (this.invoicesCreditNoteTab[i].name == tabName) {
          this.tab = i;
          break;
        }
      }
    },
    submitForm() {
      let expirationDate = new Date(this.invoice.expirationDate)
      expirationDate.setHours(0, 0, 0, 0)
      let emittedAt =  new Date(this.invoice.emittedAt)
      emittedAt.setHours(0, 0, 0, 0)
      if(expirationDate.getTime() < emittedAt.getTime()) {
        this.$delegates.snackbar("La data di scadenza non può essere antecedente a quella di emissione!");
        return;
      }
      delete this.invoice.invoiceGenericElements;
      delete this.invoice.invoiceItems;
      delete this.invoice.invoiceServices;
      delete this.invoice.invoiceCustomer;
      delete this.invoice.invoiceSupplier;
      invoiceService.update(this.invoice).then((result) => {
        this.close()
      });
    },
    payAndClose() {
      invoiceService.pay(this.invoice).then((result) => {
        this.$delegates.snackbar("Fattura pagata correttamente");
        this.close()
      });
    },
    close() {
      if(!!this.pathName)
        this.$router.push({name: this.pathName, params: { filters: this.filters }})
      else
        this.$router.push({path:this.pathToGoBack})
    },
    fetchInvoice() {
      this.loadingInvoice = true;
      invoiceForm.resetInvoice();
      invoiceService.get(this.id).then((invoice) => {
        this.loadingInvoice = false;
        this.currentProps;
        invoiceForm.updateObject(invoice);
        this.title =
          "Dettaglio Fattura " +
          invoice.internalCode +
          " - " +
          invoice.description +
          " - " +
          invoice.lastname +
          " " +
          (invoice.firstname ? invoice.firstname : "");
        invoice.description;
        this.handleStatusColor(invoice);
        this.handleTypeColor(invoice);
      });
    },
    handleStatusColor(invoice) {
      if (invoice.status == "paid") {
        if (invoice.type == "warehouse") {
          this.statusLabelColor = "Pagata";
          this.statusColor = "red lighten-3";
        } else {
          this.statusLabelColor = "Incassata";
          this.statusColor = "green lighten-3";
        }
      } else if (invoice.status == "cancelled") {
        this.statusLabelColor = "Cancellata";
        this.statusColor = "red";
      } else if (invoice.status == "pending" && invoice.type == "warehouse") {
        this.statusLabelColor = "Pagamento da effettuare";
        this.statusColor = "red lighten-3";
      } else if (invoice.status == "pending") {
        this.statusLabelColor = "Fattura in attesa";
        this.statusColor = "blue lighten-3";
      } else if (invoice.status == "cashed") {
        this.statusLabelColor = "Incassata";
        this.statusColor = "green lighten-3";
      }
    },
    handleTypeColor(invoice) {
      if (invoice.type == "warehouse") {
        this.typeLabelColor = "Automatica magazzino";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "restaurant") {
        this.typeLabelColor = "Manuale Ristorante";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "other") {
        this.typeLabelColor = "Altro";
        this.typeColor = "gray lighten-3";
      }  else if (invoice.type == "saloon") {
        this.typeLabelColor = "Manuale Salone";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "cashDesk") {
        this.typeLabelColor = "Automatica in cassa";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "otherToCash") {
        this.typeLabelColor = "Manuale";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "creditNote") {
        this.typeLabelColor = "Nota a Credito";
        this.typeColor = "gray lighten-3";
      } else if (invoice.type == "creditNoteToPay") {
        this.typeLabelColor = "Nota a Credito";
        this.typeColor = "gray lighten-3";
      }
    },
  },
  computed: {
    currentProps() {
     if (this.currentComponent === "InvoiceElementFormEditTab") {
        return { invoice: this.invoice, bus: this.bus, variant: this.variant };
      } else if (this.currentComponent === "CreditNoteFormTab") {
        return { invoice: this.invoice, variant: this.variant };
      } else return;
    },
  },
  watch: {
    openDialog(newVal) {
      this.invoiceDialog = newVal;
    },
    tab(newVal) {
      this.currentComponent = this.invoicesCreditNoteTab[newVal].component;
      this.currentProps;
      try {
        window.history.pushState(
          "Tab",
          "Tab",
          this.originalUrl + "?tabName=" + this.invoicesCreditNoteTab[newVal].name
        );
      } catch (err) {
        console.log("try setting the tab name but something goes wrong");
      }
    },
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  }
};
</script>